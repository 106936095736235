import 'angular';

import { downgradeInjectable } from '@angular/upgrade/static';

import { InvoicesForBillerPaymentCtrl } from './invoices.for-biller-payment.ajs';
import { InvoicesForPaymentLiteCtrl }   from './invoices.for-payment';
import { InvoiceStatusFilter }          from './invoice.status.filter.ajs';
import { InvoiceStatusPipe }            from './invoice.status.pipe';
import { InvoiceTypeFilter }            from './invoice.type.filter.ajs';
import { InvoiceTypePipe }              from './invoice.type.pipe';

angular.module('app-lite.invoices', [])
  .controller('InvoicesForBillerPaymentCtrl', InvoicesForBillerPaymentCtrl)
  .controller('InvoicesForPaymentLiteCtrl', InvoicesForPaymentLiteCtrl)
  .filter('invoiceStatus', InvoiceStatusFilter)
  .filter('invoiceType', InvoiceTypeFilter)
  .service('invoiceStatusPipe', downgradeInjectable(InvoiceStatusPipe))
  .service('invoiceTypePipe', downgradeInjectable(InvoiceTypePipe));