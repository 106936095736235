import 'angular';

export function VendorInvoicesForPaymentLiteCtrl ($scope, $translate, currencyInfo, invoices) {

  $scope.currency = currencyInfo();
  $scope.invoices = invoices || [];
  $scope.selectedItems = [];

  $scope.tableSettings = {
    columns: [
      {
        classes: 'text-left',
        field  : 'vendor_invoice_number',
        header : {
          text: $translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
        },
      },
      {
        classes : 'text-left',
        field   : 'post_date',
        header  : {
          text: $translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
        },
        pipe    : 'date',
        pipeArgs: 'mediumDate'
      },
      {
        classes: 'text-left',
        field  : 'total',
        header : {
          text: $translate.instant('JS_SPACE.COLUMNS.AMOUNT')
        },
        pipe   : 'globalCurrency'
      },
      {
        classes: 'text-left',
        field  : 'balance_due',
        header : {
          text: $translate.instant('JS_SPACE.COLUMNS.BALANCE')
        },
        pipe   : 'globalCurrency'
      }
    ],
    empty: {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      highlight: {
        enabled: true
      },
      select: {
        enabled: true,
        multi  : true
      }
    },
    sorting: {
      enabled: false
    },
    static: true
  };

  $scope.onRowClick = function (event) {
    $scope.selectedItems = $scope.invoices.filter((invoice) => invoice.selected);
    $scope.allSelected = $scope.selectedItems.length === $scope.invoices.length;
  };

  $scope.selectAll = function () {
    $scope.allSelected = !$scope.allSelected;

    angular.forEach($scope.invoices, function (invoice) {
      invoice.selected = $scope.allSelected;
    });

    $scope.selectedItems = $scope.allSelected ? $scope.invoices : [];
  };

  $scope.add = function () {
    $scope.$close($scope.selectedItems);
  };

}