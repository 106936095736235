/**
 * handles all json manipulation like deep copying and parsing.
 * Some built in methods are not the most efficient way so these
 * methods offer alternatives.
 */
export class JsonApiService {
  constructor (
    private $location: ng.ILocationService
  ) {}

  // Creates a copy of an object, breakinf the reference
  copy ( obj = {} ) : any {
    return angular.fromJson(angular.toJson(obj));
  }

  // takes all the query parameters and parses them into a json object
  getQueryParameters () : any {
    const params      = new URLSearchParams(parent.window.location.search);
    const queryParams = this.$location.search();

    const paramObj = {};

    for ( const key of params.keys() ) {
      paramObj[key] = decodeURIComponent(params.get(key));
    }

    for ( const key in queryParams ) {
      paramObj[key] = decodeURIComponent(queryParams[key]);
    }

    return this.copy(paramObj);
  }
}