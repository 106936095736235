export function AuthVerifyCtrl ($scope, $state, $stateParams, $translate, $urlService, messages, workshopAPI) {
  $scope.customer_id     = $stateParams.customer_id;
  $scope.subscription_id = $stateParams.subscription_id;

  $scope.subscription = {
    username: $stateParams.email,
    email: '',
    password: '',
    unlock_code: $stateParams.unlock_code,
    country_code: $urlService.config.host().indexOf('au') >= 0 ? 'aus': 'usa'
  };

  workshopAPI.get('/system/country_types', null, null, {
    bypass: true
  }).then(function (countries) {
    $scope.country_codes = countries;
  });

  $scope.save = function(form) {
    workshopAPI.post('/subscriptions/first_user_setup', $scope.subscription).then(() => {
      messages.show($translate.instant('JS_SPACE.MESSAGES.USER_CREATED_CHARGIFY'), 'success');

      $state.go('app.login');
    }).catch((data, status, headers, config) => {
      messages.error(data, status, headers, config);
    });
  };

  if ($scope.customer_id && $scope.subscription_id) {
    workshopAPI.get(`/subscriptions/verify_chargify_subscription/${$scope.customer_id}/${$scope.subscription_id}`).catch((data, status, headers, config) => {
      messages.error(data, status, headers, config);
    });
  }
}