import * as angular from 'angular';

import { BillerService }          from '../biller/biller.service';
import { MessengerService }       from '../messages/messenger.service.ajs';
import { VendorModel }            from '../vendors/models/vendor';
import { VendorPaymentModel }     from './models/vendor-payment';
import { VendorPaymentsApiModel } from './models/vendor-payment.api';
import { VendorPaymentItemModel } from './models/vendor-payment.item';

import DynamicColorRowHtml              from '../grids/templates/row.dynamic-color.html';
import EditableCurrencyCellHtml         from '../grids/templates/cell.currency.editable.html';
import EmailVendorPaymentRemittanceHtml from './templates/vendor-payment.email-remittance.html';
import InvoicesForBillerPaymentHtml     from '../invoices/invoices.for-biller-payment.html';
import InvoicesForPaymentHtml           from '../invoices/invoices.for-payment.html';
import RemoveRowCellHtml                from '../grids/templates/cell.remove-row.html';
import WindowFlipHtml                   from '../core/templates/window-flip.html';

export function VendorPaymentsService (
  $q          : ng.IQService,
  $rootScope  : any,
  $state      : ng.ui.IStateService,
  $translate  : ng.translate.ITranslateService,
  $uibModal   : ng.ui.bootstrap.IModalService,
  billerApi   : BillerService,
  confirm     : any,
  dateParsing : any,
  messengerApi: MessengerService,
  workshopAPI : any
) {
  class VendorPayments implements VendorPaymentsApiModel {
    $q          : ng.IQService                   = $q;
    $rootScope  : any                            = $rootScope;
    $state      : ng.ui.IStateService            = $state;
    $translate  : ng.translate.ITranslateService = $translate;
    $uibModal   : ng.ui.bootstrap.IModalService  = $uibModal;
    billerApi   : BillerService                  = billerApi;
    confirm     : any                            = confirm;
    dateParsing : any                            = dateParsing;
    messengerApi: MessengerService               = messengerApi;
    workshopAPI : any                            = workshopAPI;

    addBillerCredit ( payment : VendorPaymentModel ) : void {
      this.$q.when(true)
      .then(() => {
        const unsavedItems = payment.vendor_payment_items_attributes.filter(item => item.vendor_invoice_id && !item.id);

        return unsavedItems.length
        ? this.confirm.generic(this.$translate.instant('JS_SPACE.CONFIRM.LOSS_OF_UNSAVED_INFORMATION'))
          .then(() => this.save(payment))
          .then(payment => {
            this.$state.go('app/vendor-payments', {
              vendor_id: payment.vendor_id,
              id       : payment.id
            }, {
              location: 'replace'
            });

            return this.$q.when(true);
          })
        : this.$q.when(true);
      })
      .then(() => this.billerApi.openLookup(payment.vendor_id, true));
    }

    addInvoices ( payment : VendorPaymentModel ) : angular.IPromise<VendorPaymentModel> {
      return this.workshopAPI.get('/vendor', payment.vendor_id)
      .then(( vendor : VendorModel ) => {
        return vendor.biller
        ? this.$uibModal.open({
          controller       : 'InvoicesForBillerPaymentCtrl',
          controllerAs     : '$ctrl',
          templateUrl      : InvoicesForBillerPaymentHtml,
          windowClass      : 'x-lg-modal flip-container',
          windowTemplateUrl: WindowFlipHtml,
          resolve          : {
            billers : () => this.workshopAPI.get('/vendors/biller_list', [ payment.vendor_id, 0, '*', 'P', 'I', '*','*' ,'*', 'company_name', 'desc']),
            isVendor: true
          }
        }).result
        : this.$uibModal.open({
          controller : 'VendorInvoicesForPaymentLiteCtrl',
          templateUrl: InvoicesForPaymentHtml,
          windowClass: 'x-lg-modal',
          resolve    : {
            invoices: () => this.workshopAPI.get('/vendors/invoice_list', [ payment.vendor_id, 0, '*', 'P', 'I', '*','*' ,'*' , 'post_date', 'desc'])
          }
        }).result;
      })
      .then(( invoices : Array<any> ) => {
        const items : Array<VendorPaymentItemModel> = [];

        angular.forEach(invoices, invoice => {
          let item : VendorPaymentItemModel = {
            acquired_type             : '',
            id                        : '',
            stock_number              : '',
            vendor_payment_id         : payment.id,
            vendor_invoice_id         : invoice.id,
            vendor_id                 : invoice.vendor_id,
            vendor_name               : invoice.vendor_name,
            vendor_invoice_number     : invoice.vendor_invoice_number,
            vendor_invoice_post_date  : invoice.post_date,
            vendor_invoice_total      : invoice.total,
            amount                    : invoice.balance_due,
            vendor_invoice_balance_due: invoice.balance_due,
            untouched                 : false
          };

          item = this.prepPaymentItem(payment, item);

          items.push(item);
        });

        payment.vendor_payment_items_attributes = payment.vendor_payment_items_attributes.filter(item => item.vendor_invoice_id);
        payment.vendor_payment_items_attributes = payment.vendor_payment_items_attributes.concat(items);

        return payment;
      });
    }

    addItems ( payment : VendorPaymentModel ) : angular.IPromise<VendorPaymentModel> {
      return this.workshopAPI.get('/vendor', payment.vendor_id)
      .then(( vendor : VendorModel ) => this.addInvoices(payment));
    }

    delete ( payment : VendorPaymentModel ) : angular.IPromise<boolean> {
      return this.confirm.generic(this.$translate.instant('JS_SPACE.CONFIRM.DELETE_PAYMENT'))
      .then(() => this.workshopAPI.delete('/vendor_payment', payment.id));
    }

    deleteItems ( payment : VendorPaymentModel ) : angular.IPromise<VendorPaymentModel> {
      payment = angular.copy(payment);

      return payment.itemsToDelete.length && payment.id
      ? this.workshopAPI.patch('/vendor_payment', {
        id                             : payment.id,
        amount                         : payment.amount,
        vendor_payment_items_attributes: payment.itemsToDelete,
      }, 'vendor_payment')
      .then(( payment : VendorPaymentModel ) => {
        payment.itemsToDelete = [];

        return payment;
      })
      : this.$q.when(payment);
    }

    email ( vendor : VendorModel, payment: VendorPaymentModel ) : angular.IPromise<VendorPaymentModel> {
      return this.messengerApi.email({
        email          : vendor.email,
        id             : vendor.id,
        message        : $rootScope.Company.company.supplier_email_message,
        send_remittance: true,
        subject        : this.$translate.instant('JS_SPACE.EMAIL.VARIABLE_SUBJECT', {
          name: $rootScope.Company.company.company_name,
          num : payment.vendor_payment_number ? (' number ' + payment.vendor_payment_number) : '',
          type: 'Payment'
        }),
        vendor_payment_attachment_id: '',
        vendor_payment_id           : payment.id
      }, {
        bodyParamName: 'vendor',
        size         : 'lg',
        templateUrl  : EmailVendorPaymentRemittanceHtml,
        url          : '/vendor_payment/email_remittance'
      });
    }

    get ( vendor_id : string, id : string ) : angular.IPromise<VendorPaymentModel> {
      return this.$q.when()
      .then(() => ( vendor_id && id ) ? this.workshopAPI.get('/vendor_payment', id) : this.$q.when({}))
      .then(( payment : VendorPaymentModel ) => this.prepPayment(vendor_id, payment));
    }

    getGridSettings ( vendor : VendorModel, editable : boolean ) : any {
      return {
        cellEditableCondition  : 'editable',
        data                   : 'payment.vendor_payment_items_attributes',
        enableCellEditOnFocus  : true,
        enableCellSelection    : true,
        enableMultiSelect      : false,
        enableRowSelection     : false,
        rowHeight              : 43,
        rowTemplate            : DynamicColorRowHtml,
        virtualizationThreshold: 9999,
        columnDefs             : [
          {
            cellTemplate  : RemoveRowCellHtml,
            disabled      : !editable,
            enableCellEdit: false,
            width         : '43px'
          },
          {
            cellClass     : 'Uppercase ReadOnly',
            displayName   : this.$translate.instant('GENERAL_SPACE.FIELD.REFERENCE'),
            enableCellEdit: false,
            field         : 'vendor_invoice_number'
          },
          {
            cellClass     : 'Capitalize ReadOnly',
            cellFilter    : 'date:"dd/MM/yyyy"',
            displayName   : this.$translate.instant('GENERAL_SPACE.FIELD.POST_DATE'),
            enableCellEdit: false,
            field         : 'vendor_invoice_post_date',
            width         : '150px'
          },
          {
            cellClass     : 'Uppercase ReadOnly',
            displayName   : this.$translate.instant('VENDOR_SPACE.SUPPLIER'),
            enableCellEdit: false,
            field         : 'vendor_name'
          },
          {
            cellClass     : 'align-right ReadOnly',
            cellFilter    : 'globalCurrency',
            displayName   : this.$translate.instant('GENERAL_SPACE.FIELD.BALANCE_DUE_LABEL'),
            enableCellEdit: false,
            field         : 'vendor_invoice_balance_due',
            width         : '150px'
          },
          {
            cellClass           : 'align-right',
            cellFilter          : 'globalCurrency',
            displayName         : this.$translate.instant('JS_SPACE.COLUMNS.APPLIED'),
            editableCellTemplate: EditableCurrencyCellHtml,
            field               : 'amount',
            width               : '115px'
          },
          {
            cellClass     : 'align-right ReadOnly',
            cellFilter    : 'globalCurrency',
            displayName   : this.$translate.instant('JS_SPACE.COLUMNS.BALANCE'),
            enableCellEdit: false,
            field         : 'getBalance()',
            width         : '115px'
          }
        ],
      };
    }

    hasCredits ( vendor : VendorModel ) : boolean {
      const balance = new BigDecimal(vendor.credit_balance ? vendor.credit_balance.toString() : '0');

      return balance.isGreaterThan(BigDecimal.ZERO);
    }

    isOpen ( payment : VendorPaymentModel ) : boolean {
      return payment.status === 'O';
    }

    prepPayment ( vendor_id: string, payment : VendorPaymentModel ) : VendorPaymentModel {
      if (payment.id) {
        payment.itemsToDelete = [];

        angular.forEach(payment.vendor_payment_items_attributes, ( item : VendorPaymentItemModel ) => this.prepPaymentItem(payment, item));

        if (!payment.vendor_payment_items_attributes.length) {
          payment.vendor_payment_items_attributes.push(this.prepPaymentItem(payment));
        }

        return payment;
      }

      return {
        amount                         : '',
        applied_amount                 : '',
        id                             : '',
        itemsToDelete                  : [],
        note                           : '',
        post_date                      : dateParsing.today(),
        reference                      : '',
        status                         : 'O',
        vendor_id                      : vendor_id,
        vendor_payment_items_attributes: [ this.prepPaymentItem(payment) ],
        vendor_payment_number          : ''
      };
    }

    prepPaymentItem ( payment: VendorPaymentModel, item ?: VendorPaymentItemModel ) : VendorPaymentItemModel {
      const self = this;

      item = item || {
        amount                    : '',
        acquired_type             : '',
        id                        : '',
        stock_number              : '',
        vendor_invoice_balance_due: '',
        vendor_invoice_id         : '',
        vendor_invoice_number     : '',
        vendor_invoice_post_date  : '',
        vendor_invoice_total      : '',
        vendor_name               : '',
        vendor_payment_id         : payment.id,
      };

      item.untouched  = angular.isDefined(item.untouched) ? item.untouched : !item.id;
      item.getBalance = function () {
        var amount  = new BigDecimal( item.amount ? item.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);
        var balance = new BigDecimal( item.vendor_invoice_balance_due ? item.vendor_invoice_balance_due.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

        if (!self.isOpen(payment)) {
          return item.vendor_invoice_balance_due;
        }

        if (balance.isLessThanOrEqualTo(BigDecimal.ZERO)) {
          return 0;
        }

        return balance.subtract(amount).toString();
      };

      return item;
    }

    process ( payment : VendorPaymentModel, options = {} ) : angular.IPromise<VendorPaymentModel> {
      angular.extend({}, options);

      return this.confirm.generic(this.$translate.instant('JS_SPACE.CONFIRM.SAVE_PROCESS_PAYMENT'))
      .then(() => this.save(payment))
      .then(( payment : VendorPaymentModel ) => this.workshopAPI.get('/vendor_payments/process_payment', payment.id, null, options));
    }

    removeItem ( payment : VendorPaymentModel, index : number ) : VendorPaymentModel {
      const deletedItem = payment.vendor_payment_items_attributes.splice(index, 1)[0];

      if (payment.id && deletedItem.id) {
        deletedItem._destroy = '1';

        payment.itemsToDelete.push(deletedItem);
      }

      if (!payment.vendor_payment_items_attributes.length) {
        payment.vendor_payment_items_attributes = [ this.prepPaymentItem(payment) ];
      }

      return payment;
    }

    save ( payment : VendorPaymentModel ) : angular.IPromise<VendorPaymentModel> {
      const method = payment.id ? 'patch': 'post';
      const url    = payment.id ? '/vendor_payment': '/vendor_payments';

      return this.deleteItems(payment)
      .then(payment => workshopAPI[method](url, payment, 'vendor_payment', 'vendor_payment_items_attributes'));
    }

    updateTotals ( payment : VendorPaymentModel ) : VendorPaymentModel {
      let total = BigDecimal.ZERO;

      angular.forEach(payment.vendor_payment_items_attributes, item => {
        const amount = new BigDecimal( item.amount ? item.amount.toString(): '0' ).setScale(2, BigDecimal.ROUND_HALF_UP);

        total = total.add(amount);
      });

      payment.applied_amount = total ? total.toString(): '0';
      payment.amount         = payment.applied_amount;

      return payment;
    }

    void ( payment : VendorPaymentModel ) : angular.IPromise<boolean> {
      return this.confirm.generic(this.$translate.instant('JS_SPACE.CONFIRM.VOID_PAYMENT'))
      .then(() => this.workshopAPI.get('/vendor_payments/void_payment', payment.id));
    }
  }

  return new VendorPayments();
};
