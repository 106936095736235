import ChangeVendorHtml         from './vendor.change.html';
import InvoicesForPaymentHtml   from '../invoices/invoices.for-payment.html';
import LinkedVendorsHtml        from './vendors.linked-vendors.html';
import ManuallyLinkVendorHtml   from './vendor.manually-link.html';
import ResolveVendorMatchesHtml from './vendor.resolve-matches.html';
import VendorQuickAddHtml       from './vendor.quick-add.html';

export function VendorServiceLite ($uibModal, $q, $rootScope, branchesApi, workshopAPI) {
  var Vendor = function () {
    this.$uibModal = $uibModal;

    this.workshopAPI   = workshopAPI;
  };

  Vendor.prototype.change = function () {
    const self = this;

    return $uibModal.open({
      controller: 'ChangeVendorLiteCtrl',
      keyboard  : false,
      resolve   : {
        vendor () {
          return self.vendor;
        }
      },
      templateUrl: ChangeVendorHtml
    }).result;
  };

  Vendor.prototype.deleteLinkedVendor = function(vendor) {
    const self = this;

    return workshopAPI.get('/vendors/destroy_branch_link', [
      self.vendor.id,
      vendor.company_id,
      vendor.id
    ]);
  };

  Vendor.prototype.findMatchingVendors = function (vendor) {
    return workshopAPI.get('/branch_matching/find_matches_for_vendor', vendor.id);
  };

  Vendor.prototype.getBiller = function () {
    const self = this;

    return workshopAPI.get('/vendor', self.vendor.biller_id);
  };

  Vendor.prototype.getBranchVendorInvoices = function () {
    const self = this;

    return workshopAPI.get('/vendors/branch_invoice_count', [
      self.vendor.id,
      'P',
      'I'
    ]).then(count => {
      return workshopAPI.get('/vendors/branch_invoice_list', [
        self.vendor.id,
        0,
        count,
        'P',
        'I',
        'post_date',
        'desc'
      ]);
    });
  };

  Vendor.prototype.getLinkedVendors = function () {
    const self = this;

    return workshopAPI.get('/vendors/branch_link_index', self.vendor.id);
  };

  Vendor.prototype.isBranchVendor = function (vendor = this.vendor) {
    return vendor.is_branch_vendor;
  };

  Vendor.prototype.isLinkedToHeadOffice = function (vendor = this.vendor) {
    return !!vendor.branch_link;
  };

  Vendor.prototype.lookupBranchVendorInvoices = function() {
    const self = this;

    return $uibModal.open({
      templateUrl: InvoicesForPaymentHtml,
      windowClass: 'x-lg-modal',
      controller : 'VendorInvoicesForPaymentLiteCtrl',
      resolve    : {
        invoices () {
          return self.getBranchVendorInvoices();
        }
      }
    }).result;
  };

  Vendor.prototype.manuallyLinkVendor = function () {
    const self = this;

    return branchesApi.lookupLinkedCompanies().then(company => {
      return $uibModal.open({
        controller: 'ManuallyLinkVendorLiteCtrl',
        resolve   : {
          company () {
            return company;
          },
          vendor () {
            return self.vendor;
          }
        },
        templateUrl: ManuallyLinkVendorHtml,
        windowClass: 'x-lg-modal'
      }).result;
    });
  };

  Vendor.prototype.resolveMatches = function (vendor, matches) {
    return $uibModal.open({
      controller: 'ResolveVendorMatchesLiteCtrl',
      keyboard  : false,
      resolve   : {
        vendor () {
          return vendor;
        },
        matches () {
          return matches;
        }
      },
      templateUrl: ResolveVendorMatchesHtml,
      windowClass: 'x-lg-modal'
    }).result.catch(() => $q.when(vendor));
  };

  Vendor.prototype.save = function (settings = {}) {
    const self   = this;
    const method =  self.vendor.id ? 'patch': 'post';
    const url    = self.vendor.id ? '/vendor': '/vendors';

    return workshopAPI[method](url, self.vendor, 'vendor', null, settings).then(vendor => {
      return ( !self.vendor.id && $rootScope.Company.isSharedAccounting() && $rootScope.Company.isAccountingSetup() ) ?
        self.findMatchingVendors(vendor).then(matches => {
          return matches.length ? self.resolveMatches(vendor, matches).then(() => {
            return vendor;
          }) : vendor;
        }) : vendor;
    });
  };

  Vendor.prototype.saveLinkedVendor = function (company, linkedVendor) {
    const self = this;

    return workshopAPI.get('/vendors/link_to_branch', [ self.vendor.id, company.company_id, linkedVendor.id ]);
  };

  Vendor.prototype.saveMatch = function (match) {
    const self = this;

    return workshopAPI.post('/branch_matching/link_vendor_ids', [
      { id: self.vendor.id, accounting_id: match.accounting_id }
    ], 'links');
  };

  Vendor.prototype.searchVendorsInCompany = function (company, filters) {
    filters = angular.extend({
      linked_company_id: company.company_id,
      search_term      : '',
      search_type      : 'starts_with'
    }, filters || {});

    return workshopAPI.post('vendors/find_in_branch', filters);
  };

  Vendor.prototype.viewLinkedVendors = function() {
    const self = this;

    return $uibModal.open({
      controller: 'LinkedVendorsLiteCtrl',
      keyboard  : false,
      resolve   : {
        links () {
          return self.getLinkedVendors();
        },
        vendor () {
          return self.vendor;
        }
      },
      templateUrl: LinkedVendorsHtml,
      windowClass: 'lg-modal'
    }).result;
  };

  Vendor.get = function (id) {
    let vendor;

    return id ? workshopAPI.get('/vendor', id).then(result => {
      vendor = result;

      return workshopAPI.get('/vendor/biller_account_balance', id);
    }).then(balance => {
      vendor.biller_balance = balance;

      return workshopAPI.get('/vendor/biller_credit_balance', id);
    }).then(creditBalance => {
      vendor.biller_credit_balance = creditBalance;

      return vendor;
    }) : $q.when({
      address1      : '',
      address2      : '',
      balance       : '0.00',
      company_name  : '',
      contact1_first: '',
      contact2_first: '',
      contact1_last : '',
      contact2_last : '',
      contact1_phone: '',
      contact2_phone: '',
      country_code  : $rootScope.Company.company.country_code,
      credit_balance: 0.00,
      email         : '',
      fax           : '',
      id            : '',
      phone         : '',
      postcode      : '',
      state         : '',
      suburb        : '',
      web           : ''
    });
  };

  Vendor.quickAdd = function () {
    return $uibModal.open({
      controller : 'VendorQuickAddLiteCtrl',
      templateUrl: VendorQuickAddHtml,
      windowClass: 'md-modal'
    }).result;
  };

  return Vendor;
}