import 'angular';

import { BranchVendorInvoicesDirective }    from './vendor-invoices.branch.directive';
import { BranchVendorInvoicesLiteCtrl }     from './vendor-invoices.branch';
import { VendorInvoicesForPaymentLiteCtrl } from './vendor-invoices.for-payment';

angular.module('app-lite.vendor-invoices', [])
  .controller('BranchVendorInvoicesLiteCtrl', BranchVendorInvoicesLiteCtrl)
  .controller('VendorInvoicesForPaymentLiteCtrl', VendorInvoicesForPaymentLiteCtrl)
  .directive('vendorBranchInvoices', BranchVendorInvoicesDirective);
