export function InvoicesForPaymentLiteCtrl (
  $scope,
  $translate,
  customer,
  Customer,
  invoices,
  workshopAPI,
  messages,
  sessionObjAPI,
  tableApi
) {
  $scope.Customer      = new Customer(customer);
  $scope.currency      = sessionObjAPI.currency();
  $scope.invoices      = invoices || [];
  $scope.selectedItems = [];
  $scope.tableCacheKey = 'branchCustomerInvoices';

  $scope.columns = [
    {
      classes: 'text-left',
      field  : 'invoice_number',
      header : {
        text: $translate.instant('GENERAL_SPACE.FIELD.REFERENCE')
      }
    },
    {
      classes: 'text-left',
      field  : 'post_date',
      header : {
        text: $translate.instant('GENERAL_SPACE.FIELD.POST_DATE')
      },
      pipe    : 'date',
      pipeArgs: 'mediumDate'
    },
    {
      classes: 'text-right',
      field  : 'total',
      header : {
        classes: 'text-right',
        text   : $translate.instant('JS_SPACE.COLUMNS.AMOUNT')
      },
      pipe: 'globalCurrency'
    },
    {
      classes: 'text-right',
      field  : 'balance_due',
      header : {
        classes: 'text-right',
        text   : $translate.instant('JS_SPACE.COLUMNS.BALANCE')
      },
      pipe: 'globalCurrency'
    }
  ];

  if ($scope.Customer.isBranchCustomer()) {
    $scope.columns.splice(1, 0, {
      field : 'store_name',
      header: {
        text: $translate.instant('GENERAL_SPACE.FIELD.STORE')
      }
    });
  }

  $scope.tableSettings = {
    cache: {
      enabled: true,
      key    : $scope.tableCacheKey
    },
    columns: $scope.columns,
    empty  : {
      text: $translate.instant('QUERY_DROPDOWN_SPACE.GRID.NO_INVOICES_FOUND')
    },
    pagination: {
      enabled: false
    },
    rows: {
      select: {
        enabled: true,
        multi  : true
      }
    },
    sorting: tableApi.setSortingFromCache($scope.tableCacheKey, {
      direction: 'desc',
      field    : 'post_date'
    }),
    static: true
  };

  $scope.selectAll = function () {
    $scope.allSelected = !$scope.allSelected;

    angular.forEach($scope.invoices, function (invoice) {
      invoice.selected = $scope.allSelected;
    });

    $scope.selectedItems = $scope.invoices.filter((row) => row.selected);
  };

  $scope.getInvoices = ( params ) => {
    const url = $scope.Customer.isBranchCustomer() ? '/customers/branch_invoice_list' : '/customers/invoice_list';
    return workshopAPI.get(url, [
      $scope.Customer.customer.id,
      0, '*', 'P', 'I',
      params.field,
      params.direction
    ]);
  };

  $scope.sort = function () {
    const url = $scope.Customer.isBranchCustomer() ? '/customers/branch_invoice_list' : '/customers/invoice_list';

    workshopAPI.get(url, [
      $scope.Customer.customer.id,
      0, '*', 'P', 'I',
      $scope.sortOptions.fields[0],
      $scope.sortOptions.directions[0]
    ])
    .then(invoices => {
      $scope.invoices = invoices;
    })
    .catch(err => {
      messages.error(err);
    });
  };

  $scope.onRowClick = function () {
    $scope.selectedItems = $scope.invoices.filter((invoice) => invoice.selected);
    $scope.allSelected = ($scope.selectedItems.length == $scope.invoices.length);
  };

  $scope.add = function () {
    $scope.$close($scope.selectedItems);
  };
}